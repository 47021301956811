import { Table } from '@tanstack/react-table';
import { Load, Preferences, Stop, User } from '../../../types';
import { GlobalFilterValue } from '../table-components/filters/use-loads-global-filter';
import { OrderStatus } from 'harmony-constants';
import { filterStopByDestination, filterStopByOrigin } from '../../../utils/data-mapping-utils';
import moment from 'moment-timezone';
import { CARGO_COLUMN, DESTINATION_COLUMN, DRIVER_COLUMN, ORIGIN_COLUMN, STATUS_COLUMN, TRACTOR_COLUMN, TRAILER_COLUMN } from '../hooks/use-draft-load-columns';

export const isLoadRowEditable = (isRowExpanded: boolean, load: Load) => {
    return isRowExpanded && load.status !== OrderStatus.Delivered;
}

const presetableColumns = [
    ORIGIN_COLUMN,
    DESTINATION_COLUMN,
    CARGO_COLUMN,
    DRIVER_COLUMN,
    TRACTOR_COLUMN,
    TRAILER_COLUMN,
    STATUS_COLUMN,
]

export const anyColumnFiltered = (table: Table<Load>) => {
    return table.getAllColumns().map(x => x.getIsFiltered()).some(x => !!x);
}

export const anyPresetableColumnFiltered = (table: Table<Load>) => {
    return getPresetableColumns(table).filter(x => x.getIsFiltered()).some(x => !!x);
}

export const getPresetableColumns = (table: Table<Load>) => {
    return table.getAllColumns().filter(x => presetableColumns.includes(x.id));
}

export const getPageSize = (isDrafts: boolean, user: User) => {
    const preferencesKey = isDrafts ? 'drafts' : 'loads';
    const pageSize = user.preferences?.tables?.[preferencesKey]?.pageSize || 100;
    return pageSize;
}

export const globalFilterRemover = (currentGlobalFilter: any, field: keyof GlobalFilterValue) => {
    const newFilter: GlobalFilterValue = { ...currentGlobalFilter };
    delete newFilter[field];
    const length = Object.keys(newFilter).length;
    return length ? newFilter : undefined;
}

export const gridTemplateColumnsGenerator = (
    isDrafts: boolean,
    showEstimatedTotalTripTime: boolean,
    showTractor: boolean,
    showTrailer: boolean,
) => {
    const gridTemplateColumns = [
        // 3 buttons at 24px = 72 (no horizontal margin/padding)
        '72px', // selection & buttons (edit/unmerge on drafts - edit/delivery log on loads)
        '.85fr', // order number
        '1fr', // cargo
        '1.5fr', // origin
        '1.5fr', // destination
        '1fr', // first stop
        '1fr', // last stop
        ...(isDrafts ? [] : ['1fr']), // completed at
        ...(showEstimatedTotalTripTime ? ['.5fr'] : []), // total trip time
        '20px', // resource scheduling button
        '.85fr', // driver
        ...(showTractor ? ['.85fr'] : []), // tractor
        ...(showTrailer ? ['.85fr'] : []), // trailer
        '.5fr', // status
        '.4fr', // expand
    ];

    return gridTemplateColumns.join(' ');
}

export const stopGridTemplateColumnsGenerator = (isDrafts: boolean, isEditable: boolean) => {
    const gridTemplateColumns = [
        ...(isEditable ? ['30px'] : []),
        '1fr', // stop type
        '1.25fr', // cargo type
        '2fr', // location
        '.9fr', // sublocations
        '1.5fr', // scheduled
        ...(isEditable ? ['30px'] : []), // auto-calc
        ...(isDrafts ? [] : ['1.25fr']), // completedAt
        '.75fr', // units
        ...(isDrafts ? [] : ['.75fr']), // actual units
        '30px',
        ...(isEditable ? ['30px'] : []), // split
        '1.25fr', // order number
        '1.25fr', // production plan
        '60px', // notes
        '30px', // expander
        ...(isEditable ? ['30px'] : []), // delete
    ];

    return gridTemplateColumns.join(' ');
}

export const genOrderNumbers = (load: Load) => {
    return [...new Set(load.stops.map(s => s.orderNumber).filter(x => x !== null))];
}

export const genOriginIds = (load: Load) => {
    return load.stops.filter(filterStopByOrigin).map(x => x.organizationLocationId);
}

export const genDestinationIds = (load: Load) => {
    return load.stops.filter(filterStopByDestination).map(x => x.organizationLocationId);
}

export const getDraftsLoadsDefaultSort = (preferences: Preferences) => {
    return preferences.draftsLoadsDefaultSort ?? false;
}

export const getShowEstimatedTotalTripTime = (preferences: Preferences) => {
    return preferences.showEstimatedTotalTripTime ?? true;
}

export const getShowTractor = (preferences: Preferences) => {
    return preferences.showTractor ?? true;
}

export const getShowTrailer = (preferences: Preferences) => {
    return preferences.showTrailer ?? true;
}

export const getNewLoadDate = (stops: Stop[], dt: string | null) => {
    const sortedByArrivalStops = stops.toSorted((a, b) => moment(a.arrivalTime).diff(moment(b.arrivalTime)));

    if (dt && moment(dt).isBefore(moment(sortedByArrivalStops[0]?.arrivalTime))) {
        return dt;
    } else {
        return sortedByArrivalStops[0]?.arrivalTime;
    }
}
